* {
    margin: 0;
    padding: 0;
}

html,
body {
    overflow: hidden;
}

.canvas {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}
